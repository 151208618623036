angular.module('fingerink')
    .directive('templateImage', function () {
        return {
            restrict: 'A',
            replace: false,
            /*template: '<img/>',*/
            scope: {
                id: '=',
                page: '=',
                load: '&'
            },
            controller: 'templateImageCtrl'
        };
    })
    .controller('templateImageCtrl', function ($rootScope, $scope, $element, $attrs, templateService, $interval) {
        var promise;

        $scope.stop = () => $interval.cancel(promise);
        $scope.start = () => {
            promise = $interval(load, 5000);
        };

        $scope.$watch('page', function () {
            if ($scope.id && $scope.page >= 0) {
                load();
            }
        });

        $scope.$on('$destroy', function () {
            $scope.stop();
        });

        function load() {
            $scope.stop();
            templateService.getURL($scope.id, $scope.page)
                .then((response) => {
                    if (response.data) {
                        $element.attr('src', response.data);
                        $scope.load(response.data);
                    } else {
                        $scope.start();
                    }
                });
        }

    });